import 'picturefill';
import 'bootstrap-sass/assets/javascripts/bootstrap/tab';
import 'bootstrap-sass/assets/javascripts/bootstrap/scrollspy';
import 'bootstrap-sass/assets/javascripts/bootstrap/carousel';

import cookieConsent from './cookieConsent';
import videolink from './videolink';
import ProjectName from './dev';
import Search from './search';

document.addEventListener('DOMContentLoaded', () => {
    cookieConsent();
    videolink();
    ProjectName();
    Search();
});
