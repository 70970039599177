import $ from 'jquery';

function ProjectName() {
    menuMobile();
    scroll();
    alpha();
}

function menuMobile() {
    const $linkPanel = $('.main-header__link-panel');

    $linkPanel.on('click', () => {
        $(this).toggleClass('active');
        $('.main-header__nav').toggleClass('open');
    });
}

function scroll() {
    const $header = $('.main-header');
    const $win = $(window);

    $win.scroll(() => {
        if ($win.scrollTop() > 1) {
            $header.addClass('scroll');
        } else {
            $header.removeClass('scroll');
        }
    });
}

function alpha() {
    $('.alphabet-nav .nav-item .nav-link').on('click', function alphaNav(event) {
        $('.alphabet-nav .nav-item .nav-link.active').removeClass('active');
        $(this).addClass('active');
        if (this.hash != null) {
            event.preventDefault();
            // Scroll to a certain element
            document.querySelector(this.hash).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    });
}

export default ProjectName;
