import $ from 'jquery';

const defaults = {
    selectors: {},
};

/**
 * Execute a function given a delay time
 *
 * This was copied from the Flevoziekenhuis project, which in turn is a copy of
 *
 * https://ourcodeworld.com/articles/read/16/what-is-the-debounce-method-and-how-to-use-it-in-javascript
 *
 * @param {function} func
 * @param {int} wait
 * @param {boolean} immediate
 * @returns {Function}
 */
const debounce = (func, wait, immediate = false) => {
    let timeout;
    return (...args) => {
        const context = this;
        const later = () => {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
};


const Search = (options = defaults) => {
    const selectors = $.extend(
        {
            searchForm: '.search-form',
            queryField: '#query',
            searchResult: '.search-result',
            header: '.page-header h1 .term',
        },
        options.selectors,
    );

    let runningRequest = null;
    const queryField = $(selectors.queryField);

    queryField.on('input', debounce(() => {
        if (runningRequest !== null) {
            runningRequest.abort();
            runningRequest = null;
        }

        const form = $(selectors.searchForm);
        runningRequest = $.ajax({
            url: queryField.data('quick-action'),
            type: 'get',
            data: form.serialize(),
            success: (result) => {
                $(selectors.header).text(queryField.val());
                $(selectors.searchResult).html(result);
                runningRequest = null;
            },
        });
    }, 500));
};

export default Search;
